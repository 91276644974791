import React from "react";
import "./line.css"
const Line =()=>{
    return(
        <div >
          <hr className="line"/>
        
        </div>
    )
}
export default Line;